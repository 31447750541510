
$theme: #13ABF7;
.flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.justify-evenly {
    justify-content: space-evenly;
}
.justify-around {
    justify-content: space-around;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.iconfont {
    color: #666;
}
.content-center {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.toolbar {
    position: relative;
    box-sizing: border-box;
    height: 71px;
    width: 100%;
    font-size: 14px;
    background-color: #fff;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1500);
    z-index: 9;
    .data-summary {
        /* flex: 20; */
        .content-center {
            margin-top: 15px;
            margin-left: 10px;
            background-color: $theme;
            .iconfont {
                margin-right: 5px;
                color: #fff;
            }
        }
    }
    .download-material {
        /* flex: 16; */
        .custom-button {
            background-color: #fff;
            color: #666666;
            border: none;
            
            .iconfont {
                margin-right: 7px;
                font-size: 16px;
            }
            &:hover {
                color: $theme;
                .iconfont {
                    color: $theme;
                }
            }
        }
    }
    .current-funds {
        /* flex: 23; */
        .iconfont {
            margin-right: 3px;
            font-size: 25px;
        }
        .finds {
            color: #FF2424;
            font-size: 18px;
            .decorate {
                margin-right: -5px;
                font-size: 14px;
            }
        }
    }
    .current-progress {
        /* flex: 43; */
        .iconfont {
            margin-right: 7px;
            font-size: 16px;
        }
        .ready-period {
            font-size: 15px;
            font-weight: 700;
        }
    }
    .progress {
        /* flex: 66; */
        .progress-item {
            position: relative;
            width: 140px;
            height: 30px;
            margin-right: 5px;
            border: 1px solid #C9C9C9;
            .bg-box {
                position: absolute;
                width: 123px;
                height: 100%;
                top: 0;
                left: 15px;
            }
            &::after,
            &::before {
                content: "";
                position: absolute;
                width: calc(30px * 1.414 / 2);
                height: calc(30px * 1.414 / 2);
                top: 50%;
                right: -11.5px;
                bottom: 0;
                transform: translateY(-50%) rotate(45deg);
                border: 1px solid #C9C9C9;
                border-left: none;
                border-bottom: none;
                background-color: #fff;
                z-index: 99;
            }
            &::before {
                right: none;
                left: -11.5px;
                z-index: 9;
            }
        }
        .first-item {
            &::before {
                display: none;
            }
        }
        .last-item {
            &::after {
                display: none;
            }
        }
        .active {
            color: #fff;
            background-color: $theme;
            border: none;
            &::before {
                background-color: #fff;
                border: none;
            }
            &::after {
                background-color: $theme;
                border: none;
            }
        }
    }
}
:deep(.el-button--warning) {
    background-color: #FEA523;
}
