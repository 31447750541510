
.PeriodSettlement {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    .main {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 30px;
        .goods-sales-data {
            font-size: 16px;
            margin: 20px 0;
            font-weight: 700;
        }
        .table-data {
            /* flex: 1; */
            .goods {
                display: flex;
                align-items: center;
                .goods_img {
                    width: 50px;
                    height: 50px;
                    margin-right: 5px;
                }
            }
        }
        .paging {
            margin-top: 15px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
